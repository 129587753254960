import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const FeatureCard = ({ title, description, icon }) => {
  return (
    <div className="group flex flex-col items-center justify-center h-full w-full p-6 bg-white rounded-xl shadow-lg cursor-pointer transition-all duration-500 ease-in-out  transform hover:scale-105 hover:shadow-2xl hover:bg-secondary">
      <div className="mb-4 text-secondary text-3xl group-hover:text-white">
        <FontAwesomeIcon icon={icon || faCircle} />
      </div>
      <h3 className="text-xl text-center font-semibold mb-2 group-hover:text-white">
        {title}
      </h3>
      <p className="text-center group-hover:text-white">{description}</p>
    </div>
  );
};

export default FeatureCard;
