import React from "react";
import SocialMediaCard from "./SocialMediaCard";
import {
  faTwitter,
  faDiscord,
  faTelegram,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";

const Community = () => {
  return (
    <section id="community" className="bg-primary py-14">
      <div className="page_container">
        <div className="mb-10">
          <h1 className="font-extrabold text-secondary text-3xl md:text-3.5xl leading-[45px] ">
            Join Our{" "}
            <span className=" bg-gradient-to-r from-secondary to-tertiary text-transparent bg-clip-text">
              Community
            </span>
          </h1>
          <p className=" text-secondary font-medium text-sm mt-2">
            Become part of a vibrant and global community of CraterDAO
            supporters and contributors, and stay up-to-date on the latest news
            and events.
          </p>
        </div>

        <div className="flex flex-wrap items-start justify-between flex-col gap-4 md:flex-row md:items-stretch ">
          <SocialMediaCard
            icon={faTwitter}
            text="Join Our Twitter Community"
            link="https://twitter.com/Crater_DAO"
          />
          <SocialMediaCard
            icon={faDiscord}
            text="Join Our Discord Community"
            link="https://discord.gg/HBNEUsjf8n"
          />
          <SocialMediaCard
            icon={faTelegram}
            text="Join Our Telegram Community"
            link="https://t.me/craterdao"
          />
          <SocialMediaCard
            icon={faReddit}
            text="Join Our Reddit Community"
            link="https://www.reddit.com/CraterDAOofficial"
          />
        </div>
      </div>
    </section>
  );
};

export default Community;
