import React from "react";
import { Circles } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="loader">
      <Circles height="70" width="70" color="#1f87b4" />
    </div>
  );
};

export default Loader;
