import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Story from "./Pages/Story";
import MobileMenu from "./Components/MobileMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-story" element={<Story />} />
        </Routes>
        <MobileMenu />
        
      </Router>
      <ToastContainer autoClose={3000} draggableDirection="x" />
    </>
  );
}

export default App;
