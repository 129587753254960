import React, { useEffect } from "react";
import HeroImage from "../Assets/Cater-DAO-Logo-Final_White-_No_Background.png";
import { Link as ScrollLink } from "react-scroll";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Hero = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const imageVariants = {
    hidden: { x: "100%" },
    visible: { x: 0, transition: { duration: 0.8, delay: 0.6 } },
  };

  return (
    <main id="Home" className="bg-black text-white py-14">
      <div className="page_container">
        <div className="flex items-center justify-between flex-col space-y-12 md:flex-row md:space-y-0">
          <div className="hero_text w-full md:w-1/2 flex flex-col items-start space-y-6">
            <motion.h1
              ref={ref}
              initial={{ opacity: 0, y: 40 }}
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
                hidden: { opacity: 0, y: 40 },
              }}
              // transition={{ duration: 0.8 }}
              className="font-extrabold text-3.5xl leading-[45px] text-center md:text-4.5xl md:leading-[60px] md:text-left"
            >
              Creating{" "}
              <span className="bg-gradient-to-r from-secondary to-primary text-transparent bg-clip-text">
                Safe and Secure Spaces
              </span>{" "}
              in the Crypto World
            </motion.h1>
            <motion.p
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 },
              }}
              transition={{ duration: 0.8, delay: 0.3 }}
              className="text-lg text-whitishGray text-center md:text-left"
            >
              Providing security solutions, building trustworthy platforms, and
              supporting a creative collective. Join our community-led
              decentralized autonomous organization.
            </motion.p>
            <div className="btn-group flex space-y-2 flex-col items-center w-full md:flex-row md:space-x-2 md:space-y-0">
              <ScrollLink
                to="about"
                smooth={true}
                offset={-50}
                className="w-full md:w-auto"
              >
                <motion.button
                  initial="hidden"
                  animate={controls}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 },
                  }}
                  transition={{ duration: 0.8, delay: 0.6 }}
                  className="w-full md:w-auto border rounded-md text-white font-semibold py-2 px-4 transition-colors duration-300 
          bg-secondary border-secondary hover:bg-transparent hover:border-secondary"
                >
                  Our Story
                </motion.button>
              </ScrollLink>

              <ScrollLink
                to="community"
                smooth={true}
                offset={-50}
                className="w-full md:w-auto"
              >
                <motion.button
                  initial="hidden"
                  animate={controls}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 },
                  }}
                  transition={{ duration: 0.8, delay: 0.6 }}
                  className="w-full md:w-auto border rounded-md text-white font-semibold py-2 px-4 transition-colors duration-300 
          bg-transparent border-secondary hover:bg-secondary hover:border-secondary"
                >
                  Join the Community
                </motion.button>
              </ScrollLink>
            </div>
          </div>
          <motion.div
            className="hero_img w-full md:w-1/2 flex items-center justify-center"
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={imageVariants}
          >
            <img src={HeroImage} alt="showcase" className="w-full md:w-[80%]" />
          </motion.div>
        </div>
      </div>
    </main>
  );
};

export default Hero;
