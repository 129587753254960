import React, { useContext, useEffect, useState } from "react";
import Nav from "../Components/Nav";
import { AppContext } from "../AppContext";
import ProfileCard from "../Components/ProfileCard";
import Loader from "../Components/Loader";
import ContactForm from "../Components/ContactForm";
import Lock from "../Assets/security.png";
import Startup from "../Assets/startup.png";
import Footer from "../Components/Footer";
import ArtImage from "../Assets/dragnozzzBUSlogo_nowording-removebg-preview.png";
import PentaghastAvatar from "../Assets/pentaghast_avatar.svg";
import JackAvatar from "../Assets/jack_avatar.png";
import DragnozAvatar from "../Assets/dragnozz_avatar.jpg";

const Story = () => {
  const { isNavOpen } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (isNavOpen) {
      document.body.classList.toggle("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isNavOpen]);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Nav />
      <section
        className={`app ${isNavOpen ? "opacity-80" : ""} overflow-x-hidden`}
      >
        <main>
          <section className="py-10 bg-bg">
            <div className="page_container">
              <div className="">
                <h1 className="font-extrabold text-white text-3xl md:text-3.5xl leading-[45px] ">
                  The{" "}
                  <span className=" bg-gradient-to-r from-secondary to-primary text-transparent bg-clip-text">
                    Full Story
                  </span>
                </h1>

                <p className="text-sm font-medium text-whitishGray mt-2">
                  Delve deeper into the inspiring journey of CraterDAO,
                  uncovering the story behind our founding and the remarkable
                  progress we have made.
                </p>
              </div>
            </div>
          </section>

          <section className="bg-bg py-10">
            <div className="page_container">
              <h3 className="font-semibold text-white text-2xl mb-4">
                How it started
              </h3>
              <div className="flex flex-col items-center gap-6 justify-between md:flex-row">
                <div className="w-full md:w-1/2">
                  <p className="text-lg text-white">
                    CraterDAO's genesis can be traced back to the personal
                    ordeals of its founders. Following the financial losses
                    incurred from the failures of prominent industry players
                    such as Celsius and FTX, a resolute determination took hold.
                    Driven by the desire to spare others from experiencing
                    similar hardships, CraterDAO was born. This collective
                    resolve propelled them to build a platform that safeguards
                    individuals from the repercussions of industry failures,
                    fostering a more secure and reliable ecosystem for all.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    src={ArtImage}
                    alt="illustration"
                    className="w-full object-cover"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="bg-white py-10">
            <div className="page_container">
              <h3 className="font-semibold text-black text-2xl mb-4">
                The Leadership Team
              </h3>
              <div className="mb-6">
                <p className="text-lg text-black">
                  CraterDAO is led by{" "}
                  <span className="text-tertiary font-medium">Simp1ejackk</span>{" "}
                  and{" "}
                  <span className="text-tertiary font-medium">Pentaghast </span>
                  , who collaborate closely with{" "}
                  <span className="text-tertiary font-medium">Dragnozz</span> to
                  drive the organization's success. Each member brings unique
                  skills and expertise to the leadership team, guiding the
                  realization of their shared vision.
                </p>
              </div>
              <div className="flex items-center flex-col justfity-between gap-6 md:flex-row">
                <ProfileCard
                  avatar={JackAvatar}
                  alt="simp1ejackk"
                  name="Simp1ejackk"
                  title="Co-founder"
                  twitterLink="https://twitter.com/simp1ejackk"
                  discordLink="https://discord.com/channels/@me/1100013407287128107"
                />
                <ProfileCard
                  avatar={DragnozAvatar}
                  alt="Dragnozz"
                  name="Dragnozz"
                  title="Co-founder & Art God"
                  twitterLink="https://twitter.com/dragnozz"
                  discordLink="https://discord.com/channels/@me/1122232933860790282"
                />
                <ProfileCard
                  avatar={PentaghastAvatar}
                  alt="Pentaghast"
                  name="Pentaghast"
                  title="Co-founder & Lead Developer"
                  twitterLink="https://twitter.com/pentaghast666"
                  discordLink="https://discord.com/channels/@me/1112890317314732124"
                />
              </div>
            </div>
          </section>

          <section className="bg-black py-10">
            <div className="page_container">
              <h3 className="font-semibold text-white text-2xl mb-4">
                What It's About
              </h3>
              <div className="flex flex-col items-center gap-6 justify-between md:flex-row">
                <div className="w-full md:w-1/2">
                  <p className="text-lg text-white">
                    CraterDAO is driven by a mission to safeguard and empower
                    individuals within the blockchain and cryptocurrency space.
                    Our focus lies in enhancing the overall landscape, breaking
                    down barriers, and fostering an inclusive environment for
                    all participants. By reducing biases and providing
                    opportunities for meaningful engagement, we aim to create a
                    space that is accessible, equitable, and beneficial to every
                    individual involved. Through our collective efforts, we
                    strive to make a lasting impact and bring positive change to
                    the industry as a whole.
                  </p>
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    src={Lock}
                    alt="illustration"
                    className="w-full object-cover"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="bg-bg2 py-10">
            <div className="page_container">
              <h3 className="font-semibold text-white text-2xl mb-4">
                Our Tactics and Methods
              </h3>
              <div className="flex flex-col items-center gap-6 justify-between md:flex-row-reverse">
                <div className="w-full md:w-1/2">
                  <p className="text-lg text-white">
                    CraterDAO implements a diverse array of tactics and methods
                    to effectively accomplish its objectives. While the precise
                    details are kept confidential, the organization operates
                    through distinct divisions that work in tandem to achieve
                    collective goals. Additionally, CraterDAO emphasizes the
                    importance of trust and security within the space. To this
                    end, certifications will be awarded to individuals and
                    projects deemed reliable and trustworthy, fostering a more
                    secure and credible ecosystem. This certification process
                    not only bolsters confidence among less-experienced
                    participants but also provides a valuable benchmark for
                    comparing and evaluating similar initiatives. By upholding
                    these principles, CraterDAO strives to enhance the overall
                    integrity and safety of the blockchain and cryptocurrency
                    space.
                  </p>
                </div>

                <div className="w-full md:w-1/2">
                  <img
                    src={Startup}
                    alt="illustration"
                    className="w-full object-cover"
                  />
                </div>
              </div>
            </div>
          </section>
          <ContactForm />
          <Footer />
        </main>
      </section>
    </div>
  );
};

export default Story;
