import React, { useState, useEffect, useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: [
    "Community",
    "Founders & Support",
    "Treasury",
    "Advisors/Investors",
    "Marketing",
    "Private Sale",
    "Public Sale",
    "Ecosystem",
  ],
  datasets: [
    {
      data: [42, 15, 10, 7, 8, 3, 5, 10],
      backgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#7CFC00",
        "#FF5733",
      ],
      hoverBackgroundColor: [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#7CFC00",
        "#FF5733",
      ],
    },
  ],
};

const pieOptions = {
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          size: 12,
        },
      },
    },
  },
};

const TokenDistributionChart = () => {
  const [charView, setChatView] = useState([]);
  const summaryRef = useRef(null);

  useEffect(() => {
    setChatView(summaryRef?.current?.legend?.legendItems);
  }, []);

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="flex flex-col items-center justify-between space-y-6 md:flex-row md:space-y-0">
      <div className="w-full max-w-[350px] md:w-1/2">
        <Pie data={data} options={pieOptions} ref={summaryRef} />
      </div>

      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 40 }}
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
          hidden: { opacity: 0, y: 40 },
        }}
        className="w-full md:w-1/2"
      >
        {charView?.map((data, i) => (
          <div
            className="flex items-center space-x-2"
            display="flex"
            style={{ marginTop: 20 }}
            key={i}
          >
            <div
              className="h-3 w-6 rounded-md"
              style={{
                background: `${data?.fillStyle}`,
              }}
            ></div>
            <p className="text-white"> {data?.text}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default TokenDistributionChart;
