import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

const ContactForm = () => {

  const form = useRef();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  
  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('service_q1h46qn', 'template_5xsozmh', form.current, 'AsZIL2Nl5NCH0S2Lg')
      .then((result) => {
        console.log(result.text);
        toast.success("Thanks for reaching out to us at CraterDAO, we will get back to you as soon as possible");
        setName('');
        setEmail('');
        setMessage('');
        setIsSubmitting(false);
      }, (error) => {
        console.log(error.text);
        toast.error("Error occured");
        setName('');
        setEmail('');
        setMessage('');
        setIsSubmitting(false);
      });

    
  };

  return (
    <section id="contact" className="py-14">
      <div className="page_container">
        <div className="mb-10">
          <h1 className="font-extrabold text-black text-3xl md:text-3.5xl leading-[45px] ">
            Contact
          </h1>
        </div>

        <div className="w-full flex flex-col gap-8 md:flex-row md:justify-between">
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-bold mb-8">Get in touch</h2>
            <p className="mb-5 text-lg text-gray-600 md:mb-0">
              For partnerships, sponsorships, or if you're eager to learn more
              about our project, we welcome you to get in touch with us using
              the contact form below. We're excited to hear from you and explore
              potential collaborations!
            </p>
          </div>
          <form ref={form} className="w-full md:w-1/2" onSubmit={sendEmail}>
            <div className="mb-6 w-full flex flex-col items-center gap-3 md:flex-row md:items-stretch ">
              <input
                type="text"
                name="user_name"
                value={name}
                placeholder="Name"
                className="w-full border border-secondary py-4 px-2 h-6 outline-none"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="email"
                name="user_email"
                value={email}
                placeholder="Email address"
                className="w-full border border-secondary py-4 px-2 h-6 outline-none"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-2">
              <textarea
                name="message"
                value={message}
                cols="30"
                rows="5"
                placeholder="Message"
                className="w-full border border-secondary py-4 px-2 outline-none"
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <button
              className="w-full md:w-auto border text-white font-semibold py-2 px-4 transition-colors duration-300 
          bg-secondary border-secondary hover:bg-transparent hover:border-secondary hover:text-secondary"
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
