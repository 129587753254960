import React, { useContext } from "react";
import { Link as ScrollLink } from "react-scroll";
import { AppContext } from "../AppContext";

const MobileMenu = () => {
  const { isNavOpen, toggleNav } = useContext(AppContext);

  return (
    <>
      <div
        className={`mobile-nav ${
          isNavOpen ? "open" : ""
        } bg-black text-white py-10 px-6 relative`}
      >
        <button
          className="absolute top-4 left-4 px-4 py-2 text-white font-semibold cursor-pointer"
          onClick={toggleNav}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#1f87b4"
              d="M19.8 4.2c-0.4-0.4-1-0.4-1.4 0L12 10.6 5.6 4.2c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4L10.6 12l-6.4 6.4c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3L12 13.4l6.4 6.4c0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4L13.4 12l6.4-6.4C20.2 5.2 20.2 4.6 19.8 4.2z"
            />
          </svg>
        </button>
        <ul className="flex flex-col items-center space-y-4 text-xl font-semibold absolute left-1/2 top-[40%] -translate-x-[40%] -translate-y-1/2">
          <li className="text-whitishGray cursor-pointer transition-colors duration-300 hover:text-secondary">
            <ScrollLink
              to="Home"
              smooth={true}
              offset={-100}
              onClick={toggleNav}
            >
              Home
            </ScrollLink>
          </li>
          <li className="text-whitishGray cursor-pointer transition-colors duration-300 hover:text-secondary">
            <ScrollLink
              to="about"
              smooth={true}
              offset={-50}
              onClick={toggleNav}
            >
              Our Story
            </ScrollLink>
          </li>
          <li className="text-whitishGray cursor-pointer transition-colors duration-300 hover:text-secondary">
            <ScrollLink
              to="token"
              smooth={true}
              offset={-50}
              onClick={toggleNav}
            >
              The NEB Token
            </ScrollLink>
          </li>
          <li className="text-whitishGray cursor-pointer transition-colors duration-300 hover:text-secondary">
            <ScrollLink
              to="contact"
              smooth={true}
              offset={-50}
              onClick={toggleNav}
            >
              Contact
            </ScrollLink>
          </li>
        </ul>
        <ScrollLink
          to="community"
          smooth={true}
          offset={-50}
          onClick={toggleNav}
        >
          <button className="w-auto absolute bottom-[10%] right-6 left-6 border rounded-md text-white font-semibold py-2 px-4 transition-colors duration-300 bg-secondary border-secondary hover:bg-transparent hover:border-secondary">
            Join the Community
          </button>
        </ScrollLink>
      </div>
    </>
  );
};

export default MobileMenu;
