import React from "react";
import TokenDistributionChart from "./TokenDistributionChart";

const TokenDistribution = () => {
  return (
    <section className="py-10">
      <h1 className="font-semibold text-white text-2xl mb-8">
        NEB Token Distribution
      </h1>
      <TokenDistributionChart />
    </section>
  );
};

export default TokenDistribution;
