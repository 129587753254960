import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons";

const ProfileCard = ({
  avatar,
  alt,
  name,
  title,
  twitterLink,
  discordLink,
}) => {
  return (
    <div className="py-6 px-4 bg-[#f5f5f5] w-full shadow-sm shadow-[#a86ea17f] flex flex-col items-center justify-center gap-5 md:w-[30%]">
      <div className="avatar w-[150px] h-[150px]  overflow-hidden rounded-full">
        <img src={avatar} alt={alt} className="w-full object-fill" />
      </div>
      <div className="flex flex-col gap-1.5 text-center">
        <h3 className="font-semibold text-lg text-tertiary">{name}</h3>
        <p className="text-base text-secondary">{title}</p>
      </div>
      <div className="flex items-center gap-4">
        <FontAwesomeIcon
          onClick={() => window.open(twitterLink)}
          icon={faTwitter}
          className="text-xl text-tertiary cursor-pointer hover:text-secondary"
        />
        <FontAwesomeIcon
          onClick={() => window.open(discordLink)}
          icon={faDiscord}
          className="text-xl text-tertiary cursor-pointer hover:text-secondary"
        />
      </div>
    </div>
  );
};

export default ProfileCard;
