import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialMediaCard = ({ link, text, icon }) => {
  return (
    <div
      className="flex group shadow-xl cursor-pointer w-full md:w-[23%] "
      onClick={() => window.open(link)}
    >
      <div className="p-2.5 flex items-center justify-center w-[50px] bg-tertiary rounded-s-[4px] group-hover:bg-white transition-all duration-500 ease-in-out">
        <FontAwesomeIcon
          icon={icon}
          className="text-2xl text-white group-hover:text-tertiary"
        />
      </div>
      <div className="p-2.5 bg-white rounded-e-[4px] w-full group-hover:bg-tertiary transition-all duration-500 ease-in-out">
        <p className="font-semibold text-base text-tertiary group-hover:text-white">
          {text}
        </p>
      </div>
    </div>
  );
};

export default SocialMediaCard;
