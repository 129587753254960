import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FlexBox = ({
  reverse,
  image,
  moon,
  craterMoon,
  text,
  btn,
  btnText,
  alt,
  router,
  routerLink,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div
      className={`flex flex-col ${
        reverse ? "md:flex-row-reverse flex-col-reverse" : "md:flex-row"
      } items-center justify-between gap-6`}
    >
      <div className="text-white text-lg w-full md:w-1/2">
        <motion.p
          ref={ref}
          initial={{ opacity: 0, y: 40 }}
          animate={controls}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
            hidden: { opacity: 0, y: 40 },
          }}
        >
          {text}
        </motion.p>
        {btn && router ? (
          <RouterLink to={routerLink}>
            <motion.button
              initial="hidden"
              animate={controls}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 },
              }}
              transition={{ duration: 0.8, delay: 0.3 }}
              className="mt-4 w-full md:w-auto border rounded-md text-white font-semibold py-2 px-4 transition-colors duration-300 
        bg-secondary border-secondary hover:bg-transparent hover:border-secondary"
            >
              {btnText}
            </motion.button>
          </RouterLink>
        ) : (
          <motion.button
            initial="hidden"
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 50 },
            }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="mt-4 w-full md:w-auto border rounded-md text-white font-semibold py-2 px-4 transition-colors duration-300 
        bg-secondary border-secondary hover:bg-transparent hover:border-secondary"
          >
            {btnText}
          </motion.button>
        )}
      </div>
      <motion.div
        initial="hidden"
        animate={controls}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 },
        }}
        transition={{ duration: 0.8, delay: 0.6 }}
        className="w-full md:w-1/2"
      >
        {moon ? (
          <div>{craterMoon}</div>
        ) : (
          <img src={image} alt={alt} className="w-full object-cover" />
        )}
      </motion.div>
    </div>
  );
};

export default FlexBox;
