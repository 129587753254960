import React, { useContext, useEffect, useState } from "react";
import Nav from "../Components/Nav";
import Hero from "../Components/Hero";
import Features from "../Components/Features";
import OurStory from "../Components/OurStory";
import NebToken from "../Components/NebToken";
// import Roadmap from "../Components/Roadmap";
import Community from "../Components/Community";
import { AppContext } from "../AppContext";
import ContactForm from "../Components/ContactForm";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";

const Home = () => {
  const { isNavOpen } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    if (isNavOpen) {
      document.body.classList.toggle("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isNavOpen]);

  return isLoading ? (
    <Loader />
  ) : (
    <main>
      <Nav />
      <section
        className={`app ${isNavOpen ? "opacity-80" : ""} overflow-hidden`}
      >
        <Hero />
        <Features />
        <OurStory />
        <NebToken />
        {/* <Roadmap />  */}
        <Community />
        <ContactForm />
        <Footer />
      </section>
    </main>
  );
};

export default Home;
