import React, { useEffect } from "react";
import FeatureCard from "./FeatureCard";
import {
  faShield,
  faPaintBrush,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Features = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <section className="py-14 bg-bg">
      <div className="page_container">
        <div className="flex flex-col flex-wrap justify-center  gap-6 md:flex-row">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 40 }}
            animate={controls}
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
              hidden: { opacity: 0, y: 40 },
            }}
            className="w-full  md:w-[30%]"
          >
            <FeatureCard
              title="Security and Protection"
              description="Our central pursuit is to provide security, safety, and protection for others."
              icon={faShield}
            />
          </motion.div>

          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 40 }}
            animate={controls}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 0.8, delay: 0.5 },
              },
              hidden: { opacity: 0, y: 40 },
            }}
            className="w-full md:w-[30%]"
          >
            <FeatureCard
              title="Creative Collective"
              description="Our team is focused on forming a creative collective to drive innovation in the space."
              icon={faPaintBrush}
            />
          </motion.div>

          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 40 }}
            animate={controls}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: { duration: 0.8, delay: 1 },
              },
              hidden: { opacity: 0, y: 40 },
            }}
            className="w-full md:w-[30%]"
          >
            <FeatureCard
              title="Building Products"
              description="We build and supply platforms, applications, and products that are needed by, or useful for other projects in the space."
              icon={faTools}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Features;
