import React from "react";
import FlexBox from "./FlexBox";
import Image from "../Assets/IMG_20230503_091722-removebg-preview.png";
import TokenDistribution from "./TokenDistribution";

const NebToken = () => {
  return (
    <section id="token" className="bg-bg2 py-14">
      <div className="page_container">
        <div className="mb-10 md:mb-6">
          <h1 className="font-extrabold text-white text-3xl md:text-3.5xl leading-[45px] ">
            The{" "}
            <span className=" bg-gradient-to-r from-secondary to-primary text-transparent bg-clip-text">
              NEB Token
            </span>
          </h1>

          <p className="text-sm font-medium text-whitishGray mt-2">
            Learn about the innovative features and uses of the NEB token, and
            how it powers our ecosystem.
          </p>
        </div>

        <FlexBox
          btn
          text="NEB Token is a deflationary token on the Algorand blockchain with a total supply of 10 billion tokens. It has a burn mechanism triggered upon removal from the staking platform and a 3% transaction fee for the first six months after release, with the fees going towards the CraterDAO Treasury for continued development funding. There are plans to potentially make NEB accessible on Polygon as well."
          image={Image}
          alt="NEB Token"
          btnText="Buy $NEB"
        />
        <TokenDistribution />
      </div>
    </section>
  );
};

export default NebToken;
