import React from "react";
import FlexBox from "./FlexBox";
import image1 from "../Assets/2130-removebg-preview.png";

const OurStory = () => {
  return (
    <section id="about" className="bg-black py-14">
      <div className="page_container">
        <div className="mb-10">
          <h1 className="font-extrabold text-white text-3xl md:text-3.5xl leading-[45px] ">
            Our{" "}
            <span className=" bg-gradient-to-r from-secondary to-primary text-transparent bg-clip-text">
              Story
            </span>
          </h1>

          <p className="text-sm font-medium text-whitishGray mt-2">
            Discover the inspiration and journey behind CraterDAO, from our
            founding to where we are today.
          </p>
        </div>

        <div>
          <FlexBox
            reverse
            btn
            image={image1}
            alt="illustration"
            text="At CraterDAO, we're on a mission to make the blockchain/cryptocurrency space safer and more accessible for everyone. We are led by a group of individuals who had their funds slashed in the wake of two major industry failures, and who resolved to create a better future for themselves and others. Our goal is to work alongside other entities in the space to prevent future events that could cause widespread losses. We believe that by improving the space as a whole, we can reduce negative biases and stigmas and make it easier for everyone to participate. Read our full story to learn more."
            btnText="Read Full Story"
            router
            routerLink="our-story"
          />
        </div>
      </div>
    </section>
  );
};

export default OurStory;
