import React from "react";
import { Link as RouterLink } from "react-router-dom";
// import Logo from "../Assets/Cater-DAO-Logo-draft_2-removebg-preview-modified.png";
import Logo from "../Assets/YYcqAn6W_400x400-modified.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faDiscord,
  faTelegram,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section className="bg-black py-14 text-white">
      <div className="page_container">
        <div className="w-full flex flex-col gap-10 justify-betwen md:flex-row">
          <div className="flex flex-col gap-4 w-full md:w-1/4">
            <RouterLink to="/">
              <div className="logo flex items-center space-x-2 cursor-pointer">
                <div className="w-[38px]">
                  <img src={Logo} alt="Logo" className="w-full" />
                </div>

                <span className="text-2xl font-bold">CraterDAO</span>
              </div>
            </RouterLink>

            <h3 className="text-white font-medium text-xl mb-3">
              Contact & Social
            </h3>
            <div className="flex items-center gap-3.5">
              <FontAwesomeIcon
                icon={faTwitter}
                className="text-primary cursor-pointer hover:text-secondary text-2xl"
                onClick={() => window.open("https://twitter.com/Crater_DAO")}
              />
              <FontAwesomeIcon
                icon={faDiscord}
                className="text-primary cursor-pointer hover:text-secondary text-2xl"
                onClick={() => window.open("https://discord.gg/K9EjKk4pJv")}
              />
              <FontAwesomeIcon
                icon={faTelegram}
                className="text-primary cursor-pointer hover:text-secondary text-2xl"
                onClick={() => window.open("https://t.me/craterdao")}
              />
              <FontAwesomeIcon
                icon={faReddit}
                className="text-primary cursor-pointer hover:text-secondary text-2xl"
                onClick={() =>
                  window.open("https://www.reddit.com/CraterDAOofficial")
                }
              />
            </div>
          </div>

          <div className=" w-full md:w-1/4">
            <h3 className="text-white font-medium text-xl mb-3">Products</h3>
            <div className="flex flex-col gap-2">
              <p className="text-whitishGray">NEB Token</p>
              <p className="text-whitishGray">Creative Collection</p>
            </div>
          </div>

          <div className=" w-full md:w-1/4">
            <h3 className="text-white font-medium text-xl mb-3">
              Organization
            </h3>
            <div className="flex flex-col gap-2">
              <p className="text-whitishGray"> Team</p>
              <p className="text-whitishGray">Community</p>
            </div>
          </div>

          <div className=" w-full md:w-1/4">
            <p>&copy; {currentYear} CraterDAO. All rights reserved.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
