import React, { useContext } from "react";
// import Logo from "../Assets/Cater-DAO-Logo-draft_2-removebg-preview-modified.png";
import Logo from "../Assets/YYcqAn6W_400x400-modified.png";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { AppContext } from "../AppContext";

const Nav = () => {
  const { toggleNav } = useContext(AppContext);

  return (
    <nav className="sticky z-50 top-0 w-full bg-black text-white py-4">
      <div className="page_container">
        <div className="flex items-center justify-between">
          <RouterLink to="/">
            <div className="logo flex items-center space-x-2 cursor-pointer">
              <div className="w-[38px]">
                <img src={Logo} alt="Logo" className="w-full" />
              </div>

              <span className="text-2xl font-bold">CraterDAO</span>
            </div>
          </RouterLink>

          <div className="nav_links hidden md:block">
            <ul className="flex items-center space-x-4 text-sm font-semibold">
              <li className="text-whitishGray cursor-pointer transition-colors duration-300 hover:text-secondary">
                <ScrollLink to="Home" smooth={true} offset={-100}>
                  Home
                </ScrollLink>
              </li>
              <li className="text-whitishGray cursor-pointer  transition-colors duration-300 hover:text-secondary">
                <ScrollLink to="about" smooth={true} offset={-50}>
                  Our Story{" "}
                </ScrollLink>
              </li>
              <li className="text-whitishGray cursor-pointer  transition-colors duration-300 hover:text-secondary">
                <ScrollLink
                  to="token"
                  smooth={true}
                  duration={500}
                  offset={-50}
                >
                  The NEB Token{" "}
                </ScrollLink>
              </li>
              <li className="text-whitishGray cursor-pointer  transition-colors duration-300 hover:text-secondary">
                {" "}
                <ScrollLink to="contact" smooth={true} offset={-50}>
                  Contact
                </ScrollLink>
              </li>
            </ul>
          </div>
          <ScrollLink to="community" smooth={true} offset={-50}>
            <button
              className="hidden md:block w-full md:w-auto border rounded-md text-white font-semibold py-2 px-4 transition-colors duration-300 
        bg-secondary border-secondary hover:bg-transparent hover:border-secondary"
            >
              Join the Community
            </button>
          </ScrollLink>

          {/* Hamburger button */}
          <div
            className="flex flex-col items-center space-y-1 w-5 cursor-pointer  md:hidden"
            onClick={toggleNav}
          >
            <div className="w-full h-0.5 bg-secondary"></div>
            <div className="w-full h-0.5 bg-secondary"></div>
            <div className="w-full h-0.5 bg-secondary"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
